import React, { useEffect, useState } from 'react';
import './Login.css';
import { NavbarLogos } from '../NavbarLogos/NavbarLogos';

export const Login = props => {

  const {title, description, logo, actualizarPreguntaActual} = props;

  // console.log("logo: " + logo);
  
  const [registros, setRegistros] = useState([]);

  const [name, setName] = useState();
  const [email, setEmail] = useState();
  const [phone, setPhone] = useState();

  const [error, setError ] = useState(false);

  const saveDataBoton = () => {
    
    if(name === undefined || email === undefined ){
      alert("Por favor ingresa todos los datos");
    } else {
      var infoList = { name, email, phone } 
      setRegistros([...registros, infoList ]);
      
      localStorage.setItem("Nombre", name);
      localStorage.setItem("Correo", email);
      localStorage.setItem("Telefono", phone);

      actualizarPreguntaActual();
    }
  }


  
  useEffect(() => { // guarda los cambios en el localStorage
    setTimeout(() => {

        try {
            // Pregunta de almacenamiento local
            const localStorageQuestion = localStorage.setItem("registros"); // guarda info en el navegador.
            // getItem, setItem son metodos

            let parsedRespuesta; // var nueva lista de array preguntas.

            if(!localStorageQuestion) { // si hay contenido 
                localStorage.setItem("registros", JSON.stringify(registros)); // envia info en forma de string
                parsedRespuesta = registros; // estado de de la app, array vacio, cuando no hayan preguntas.
            } else {
                parsedRespuesta = JSON.parse(localStorageQuestion.getItem("registros")); // JSON.parse se convierte la información en un objeto de javascript.
            }

            setRegistros(parsedRespuesta);
        } catch(error) {
            setError(error);
        }

    }, 2000);
  
  }, []);


  return (

    <>

      <NavbarLogos logo={logo} />
      
      <div className='header-login'>
        <h1>{title}</h1>
        <h4>{description}</h4>
      </div>  

        <form className='container-form' id={"form"}>

            <input name="inputChangeName" className='input' type="text" placeholder="Nombre" onChange={(e) => setName (e.target.value)}></input>
            <input name="inputChangeEmail"  className='input' type="email" placeholder='Correo Electrónico' onChange={(e) => setEmail (e.target.value)} ></input>
            <input name="inputChangeCelular"  className='input' type="number" placeholder='Teléfono' onChange={(e) => setPhone (e.target.value)} ></input>

            <button 
                className="boton new-boton-pleizt" 
                onClick={ saveDataBoton }                 
            >
              Siguiente 
            </button>
        </form>
        <NavbarLogos logo={logo} />

    </>
    
  )
}
