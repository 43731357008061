import React from "react";
import { NavbarLogos } from "../NavbarLogos/NavbarLogos";
import logoPlaizt from "../Imagenes/Logo.svg";
export const GraciasFin = ({ Mensaje, logo }) => {
  return (
    <>
      <NavbarLogos logo={logo} />
      <h1
        style={{ margin: "50px", "margin-top": "190px", textAlign: "center" }}
      >
        {Mensaje}
      </h1>
    </>
  );
};
