import React, { useEffect, useState } from "react";
import { FaStar } from "react-icons/fa";
import "./PreguntasEstrellas.css";

export const PreguntasEstrellas = (props) => {
  const {
    title,
    description,
    id,
    isLast,
    enviarData,
    actualizarPreguntaActual,
    actualizarPreguntaAnterior,
    is_required,
  } = props;

  const [puntuacion, setPuntuacion] = useState(); // se guarda el valor de la estrella que selecciona el usuario.

  const [hover, setHover] = useState(null); // cuando el mouse pasa por encima (onMouseEnter)
  // cuando mueva el mouse hacia afuera es onMouseLeave

  const [error, setError] = useState(false);

  const saveDataButton = () => {
    // console.log(puntuacion);
    if (puntuacion === undefined && is_required == "1") {
      alert("Por favor selecciona una opción");
    } else {
      const respuesta = {
        // id es la pregunta rango y el answer es la respuesta que seleciona el usuario en la pagina.
        id: id,
        answers: puntuacion,
      };
      localStorage.setItem(id, JSON.stringify(respuesta));

      if (isLast) {
        enviarData();
      } else {
        actualizarPreguntaActual();
      }
    }
  };

  // para enviar la respuesta al localStorage
  useEffect(() => {
    try {
      const localStorageAnswers = localStorage.setItem("respuesta");

      let parsedRespuesta;

      if (!localStorageAnswers) {
        localStorage.setItem("respuesta", JSON.stringify(puntuacion));
        parsedRespuesta = puntuacion;
      } else {
        parsedRespuesta = JSON.parse(localStorageAnswers.getItem("respuesta"));
      }
      setPuntuacion(parsedRespuesta);
    } catch (error) {
      setError(error);
    }
  }, []);

  return (
    <>
      <button
        style={{
          display: "flex",
          alignSelf: "flex-start",
          background: "aqua",
          border: "aqua",
          borderRadius: "5px",
          padding: "5px",
          textAlign: "center",
          cursor: "pointer",
          margin: "15px",
        }}
        onClick={() => actualizarPreguntaAnterior()}
      >
        ↶
      </button>

      <header
        className="header-preguntas"
        // style={{ marginTop: "125px" }}
      >
        <h1>{title}</h1>
        <h4>{description}</h4>
      </header>

      <form style={{ marginTop: "70px" }}>
        {[...new Array(5)].map((star, i) => {
          const valorPuntuacion = i + 1;
          // console.log(valorPuntuacion);
          return (
            <label style={{ padding: "3px" }} key={valorPuntuacion}>
              {" "}
              {/* se agrega key */}
              <input
                key="1"
                type="radio"
                name="rating"
                value={valorPuntuacion}
                onClick={() => setPuntuacion(valorPuntuacion)}
              />
              {/* Se quita para que no pase a la siguiente pagina cuando doy click en la estrella. */}
              {/* {(()=>{
                                        if(setPuntuacion ) {
                                            return (
                                                saveDataButton()
                                            )
                                        }
                                    })()} */}
              <FaStar
                className="star"
                size={55}
                color={
                  valorPuntuacion <= (hover || puntuacion)
                    ? "#F9C638"
                    : "#e4e5e9"
                }
                onMouseEnter={() => setHover(valorPuntuacion)}
                onMouseLeave={() => setHover(null)}
              />
            </label>
          );
        })}
      </form>

      <button
        className="boton new-boton-pleizt"
        onClick={() => saveDataButton()}       
      >
        Siguiente
      </button>
    </>
  );
};
